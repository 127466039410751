<template>
    <a @click="accederCorreo(correo)" href="#" class="dropdown-item" v-for="correo in correos" :key="correo.id_correo">
        <div data-toggle="tooltip" data-placement="top" :title="correo.nombre_correo">
            <i class="fas fa-envelope-open-text ml-2 mr-1"></i> {{correo.nombre_correo}}
            <span class="badge bg-warning float-right">{{ correo.n_nuevos}}</span>

        </div>
    </a>
</template>
<script>
import { ENTORNO_APUYEN } from '../../../../../../.env.js';
export default({
    props:{
        correos: Array
    },
    data() {
        return {
            url: ENTORNO_APUYEN.BaseURL,
            numero_correos_sin_leer : 0,
            isRendered: false,
        }
        
    },
    watch: {
      
    },
    methods: {
        accederCorreo (correo){
            const url = new URL(this.url);
            const domain = url.hostname;
            window.open("https://" + domain + "/"+correo.href, "_target");
        },
 
  
    }
})
</script>